import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Arrow from "../../assets/svgs/arrow-red.svg";
import Placeholder from "../../assets/images/image-placeholder.jpg";
import Section from "../../components/Section/Section";

import "./FeaturedList.scss";

const FeaturedList = ({ title, items }) => {
  return (
    <Section className="FeaturedList">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h3 className="SectionTitle">{title}</h3>
          </div>
          <div className="col-lg-8">
            {items &&
              items.map(({ title, uri, featuredImage, baseFields }, i) => {
                const itemImage = featuredImage?.node.localFile?.childImageSharp.fluid;
                return (
                  <Link to={uri} className="FeaturedListItem" key={i}>
                    <div className="row">
                      <div className="col-lg-3 plm-0">
                        <div className="FeaturedListItemImage">
                          {itemImage ? (
                            <Img fluid={itemImage} alt={title} />
                          ) : (
                            <img src={Placeholder} alt="Story" />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-7 Left--alignment">
                        <h5 className="FeaturedListItemTitle">{title}</h5>
                        <div
                          className="FeaturedListItemDescription Body--small"
                          dangerouslySetInnerHTML={{ __html: baseFields.previewDescription }}
                        />
                      </div>
                      <div className="col-lg-1 ml-auto">
                        <div className="FeaturedListItemArrow">
                          <img src={Arrow} alt="" />
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default FeaturedList;
